//@ts-nocheck
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { RangePicker } from '@gamesb42/ui-kit';
import { Table, DatePicker, EurIcon, UsdIcon, Button, Pagination, Input, Select } from '@gamesb42/ui-kit';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { formatAmount, formatPercent } from 'helpers/formatters';
import ContentWrapper from 'components/ContentWrapper';

import urls from 'constants/urls';
import TableWrapper from 'components/TableWrapper';
import MainContent from 'components/MainContent';
import { CURRENCY_OPTIONS } from 'constants/currency';
import Status from 'components/common/Status';

import s from './workingCapital.module.scss';

interface QueryParams {
  page?: number;
  limit?: number;
  order?: string;
  sortedBy?: string;
}

const statusOptions = [
  { label: <Status status="ACTIVE" style={{ width: '100%' }} />, value: 'active' },
  { label: <Status status="REPAID" style={{ width: '100%' }} />, value: 'repaid' },
];

export default function WorkingCapital() {
  const [capitalData, setCapitalData] = useState([]);
  const history = useHistory();
  const query: QueryParams = queryString.parse(history.location.search, { parseNumbers: true });
  let { limit = 10, page = 0, order, sortedBy } = query;
  const [total, setTotal] = useState(0);
  const [value, setValue] = useState<DatePickerProps['value']>(undefined);
  const [counterparty, setCounterparty] = useState(query?.counterparty);

  const [currency, setCurrency] = useState(query?.currency);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);

  const onSort = (newSortedBy: string) => {
    // console.log('onSort', newSortedBy, order);
    const calulateOrder = (currentOrder) => {
      switch (currentOrder) {
        case null:
        case '':
        case undefined:
          return 'asc';
        case 'asc':
          return 'desc';
        case 'desc':
          return '';
        default:
          break;
      }
      return '';
    };

    const calulateSortedBy = (currentOrder) => {
      switch (currentOrder) {
        case null:
        case '':
        case undefined:
          return '';
        case 'asc':
        case 'desc':
          return 'receiptDate';
        default:
          break;
      }
      return '';
    };

    const newOrder = calulateOrder(order);
    const sortedBy = calulateSortedBy(newOrder);

    return history.replace(
      `${history.location.pathname}?${queryString.stringify({
        order: newOrder,
        sortedBy: sortedBy,
      })}`,
    );
  };

  const columns = [
    {
      title: 'Issue date',
      dataIndex: 'receipt_date',
      align: 'left' as 'left',
      sorter: true,
      sortOrder: order == 'desc' ? 'descend' : order == 'asc' ? 'ascend' : '',
      onHeaderCell: () => ({
        onClick: () => onSort('receiptDate'),
      }),
      render: (_: any, record: any) => {
        return moment(record.receipt_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
    },
    {
      title: 'Counterparty',
      dataIndex: 'counterparty',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return record.counterparty;
      },
    },
    {
      title: 'Loan amount',
      dataIndex: 'amount',
      align: 'right' as 'right',
      render: (_: any, record: any) => {
        return `${record.currency === 'EUR' ? '€' : '$'}${formatAmount(record.amount)}`;
      },
    },
    {
      title: 'Outstanding',
      dataIndex: 'amount',
      align: 'right' as 'right',
      render: (_: any, record: any) => {
        return `${record.currency === 'EUR' ? '€' : '$'}${formatAmount(record.amount - record.repaid)}`;
      },
    },
    {
      title: 'Interest, %',
      dataIndex: 'interest',
      align: 'right' as 'right',
      render: (_: any, record: any) => {
        return Number.parseFloat(record.interest).toFixed(1);
      },
    },
    {
      title: 'Repayment date',
      dataIndex: 'return_date',
      align: 'right' as 'right',
      render: (_: any, record: any) => {
        return moment(record.return_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center' as 'center',
      render: (_: any, { status }) => {
        return <Status status={status.toUpperCase()} style={{ width: '100%' }} />;
      },
    },
  ];

  const onChangeQuery = useCallback(
    (newValues: Partial<QueryParams>) => {
      history.replace(
        `${history.location.pathname}?${queryString.stringify(
          { limit, page, order, sortedBy, ...newValues },
          { skipEmptyString: true },
        )}`,
      );
    },
    [history, limit, page, order, sortedBy],
  );

  const onClickRow = (id: string) => {
    if (id) {
      history.push(`${urls.getWorkingCapital()}/${id}`);
    }
  };

  function getData() {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('X-AuthToken')}`);

    console.log('useEffect', order, sortedBy);

    let params = {
      limit: limit as number,
      page: page as number,
    };

    if (order && sortedBy) {
      params.order = order;
      params.sortedBy = sortedBy;
    }

    if (value) {
      params.from_date = value[0].format('YYYY-MM-DD');
      params.to_date = value[1].format('YYYY-MM-DD');
    }

    if (counterparty) {
      params.counterparty = counterparty;
    }

    if (currency) {
      params.currency = currency;
    }

    if (status) {
      params.status = status;
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/tranche?${queryString.stringify(params)}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        setCapitalData(result.items);
        setTotal(result.total);
      })
      .catch((error) => console.log('error', error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getData();
  }, [limit, page, order, sortedBy, value, counterparty, currency, status]);

  return (
    <MainContent type="flexibleLimit" flexColumn>
      <div className={s.header}>
        <div className={s.title}>Working Capital</div>
        <Button
          onClick={() => history.push(urls.getWorkingCapitalCreate())}
          icon={<PlusOutlined style={{ color: '#fff' }} />}
          className={s.addItem}
        >
          Add tranche
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <RangePicker size="medium" style={{ marginBottom: '12px', width: '260px' }} value={value} onChange={setValue} />
        <Input
          value={counterparty}
          onChange={(e) => setCounterparty(e.target.value)}
          style={{ width: '320px' }}
          label={'Counterparty'}
          placeholder="Counterparty"
          disabled={false}
        />
        <Select
          value={currency}
          style={{ width: '112px' }}
          label={'Currency'}
          options={CURRENCY_OPTIONS}
          onChange={(e) => setCurrency(e)}
          allowClear
        />
        <Select
          value={status}
          style={{ width: '206px' }}
          label="Status"
          options={statusOptions}
          onChange={(e) => setStatus(e)}
          allowClear
        />
      </div>
      <TableWrapper>
        <Table
          loading={loading}
          onRow={({ id }) => ({
            onClick: () => onClickRow(id),
          })}
          style={{ cursor: 'pointer' }}
          columns={columns}
          dataSource={capitalData}
          className={s.table}
        />
        <Pagination
          loading={loading}
          total={total}
          current={(page + 1) as number}
          pageSize={limit as number}
          onChange={(newCurrent: number, newPageSize: number) =>
            onChangeQuery({ page: newCurrent - 1, limit: newPageSize })
          }
        />
      </TableWrapper>
    </MainContent>
  );
}
