// @ts-nocheck
import React, { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeader, Tabs, AddIcon } from '@gamesb42/ui-kit';

import urls from 'constants/urls';
import useCompany from 'hooks/api/useCompany';
import { contractDataBaseType } from 'hooks/api/useContracts';
import { getCompanyById } from 'api/company';
import { Company } from 'types/CompanyTypes';
import CompanyStatusLabel from 'components/CompanyStatus';

import General from './General';
import ActionButton from './ActionButton';
import styles from './styles.module.scss';
import AgreementButton from './Agreements/AgreementButton';
import RevenueSources from './RevenueSources';
import Docs from './Docs';
import { AMLKYC } from './AMLKYC';
import { flexbox } from '@mui/system';
import baseRequest from 'queries/baseRequest';
import { AppGameInfo } from './AppGameInfo';
import { StatusEnumTitle } from 'components/common/Status';

export interface CompanyTypeParams {
  period_from: string;
  platform_ids: string;
  tab: string;
  date_from: string;
  date_to: string;
  stores_ids: string;
}
interface ViewType {
  requestParams: CompanyTypeParams;
}

const View: FC<ViewType> = ({ requestParams }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { companyId, slug } = useParams();
  const [companyData, setCompanyData] = useState<Company>();
  const { purchaseClaims, getPdsAccounts, listPdsAccounts } = useCompany();
  const [currentDocument, setCurrentDocument] = useState<contractDataBaseType | null>(null);
  const [showAgreementButton, setShowAgreementButton] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [onboardingData, setOnboardingData] = useState<any>([]);
  const [dontKnowCount, setDontKnow] = useState(0);
  const [status, setStatus] = useState();

  const tabsItems = useMemo(() => {
    const result: any = [
      {
        label: t('forms.companies.tabs.general'),
        key: 'general',
        children: <General company={companyData as Company} />,
      },
    ];

    if (companyData?.type === 'client') {
      result.push(
        {
          key: 'general-docs',
          label: t('forms.companies.tabs.docs'),

          children: (
            <Docs
              activeMainTab={slug}
              setShowAgreementButton={setShowAgreementButton}
              setCurrentDocument={setCurrentDocument}
              companyId={companyId}
              setIsOpen={setIsOpen}
              setCompanyData={setCompanyData}
              setStatus={setStatus}
            />
          ),
        },
        {
          key: 'revenue-sources',
          label: t('forms.companies.tabs.revenueSources'),
          children: <RevenueSources listPdsAccounts={listPdsAccounts} requestParams={requestParams} />,
        },
        {
          key: 'aml-kyc',
          label: 'AML&KYC',
          children: <AMLKYC companyData={companyData} setCompanyData={setCompanyData} />,
        },
        {
          key: 'app-game-info',
          label: `App&Game Info`,
          children: (
            <AppGameInfo
              rows={onboardingData}
              getOnboardingData={getOnboardingData}
              mapOnboardingData={mapOnboardingData}
            />
          ),
        },
      );
    }

    return result;
  }, [companyData, companyId, listPdsAccounts, purchaseClaims, requestParams, t]);

  function updateCompany() {
    setCompanyData(undefined);
    getCompanyById(companyId)
      .then((data) => {
        setCompanyData(data);
      })
      .catch(() => {});
  }

  function mapOnboardingData(data: any) {
    let rows = [];
    const distribution = data.app_questionary.distribution;
    let dontCount = 0;

    // 1
    if (distribution.appleStore)
      rows.push({
        name: <span className={styles.label}>Apple Appstore</span>,
        value: (
          <a href={distribution.appleStoreComment} className={styles.link} target="_blank">
            {distribution.appleStoreComment.length > 0 ? distribution.appleStoreComment : '-'}
          </a>
        ),
      });
    if (distribution.googleStore)
      rows.push({
        name: <span className={styles.label}>Google Play</span>,
        value: (
          <a href={distribution.googleStoreComment} className={styles.link} target="_blank">
            {distribution.googleStoreComment.length > 0 ? distribution.googleStoreComment : '-'}
          </a>
        ),
      });
    if (distribution.other)
      rows.push({
        name: <span className={styles.label}>Other Store</span>,
        value: (
          <a href={distribution.otherComment} className={styles.link} target="_blank">
            {distribution.otherComment.length > 0 ? distribution.otherComment : '-'}
          </a>
        ),
      });
    if (distribution.steam)
      rows.push({
        name: <span className={styles.label}>Steam Store</span>,
        value: (
          <a href={distribution.steamComment} className={styles.link} target="_blank">
            {distribution.steamComment.length > 0 ? distribution.steamComment : '-'}
          </a>
        ),
      });

    // 2
    if (data.app_questionary.monetization) {
      if (data.app_questionary.monetization === 'I don’t know') dontCount++;

      rows.push({
        name: <span className={styles.label}>Monetization model of your app/game?</span>,
        value:
          data.app_questionary.monetization === 'I don’t know' ? (
            <span className={styles.value} style={{ color: '#EC3E72' }}>
              I don’t know
            </span>
          ) : (
            <span className={styles.value}>{data.app_questionary.monetization}</span>
          ),
      });
    }

    // 3
    if (data.app_questionary.uaSpend) {
      let usSpend = [];

      if (data.app_questionary.uaSpend.appleSearchAds) usSpend.push('Apple Ads');
      if (data.app_questionary.uaSpend.dontKnow) {
        usSpend.push('I don’t know');
      }
      if (data.app_questionary.uaSpend.facebook) usSpend.push('Facebook');
      if (data.app_questionary.uaSpend.googleAds) usSpend.push('Google Ads');
      if (data.app_questionary.uaSpend.other) usSpend.push('Other');
      if (data.app_questionary.uaSpend.snapchat) usSpend.push('Snapchat');
      if (data.app_questionary.uaSpend.tikTok) usSpend.push('TikTok');
      if (data.app_questionary.uaSpend.unityAds) usSpend.push('UnityAds');

      rows.push({
        name: <span className={styles.label}>UA spends sources:</span>,
        value:
          usSpend.length > 0 ? (
            usSpend[0] === 'I don’t know' ? (
              <span className={styles.value}>'I don’t know'</span>
            ) : (
              <span className={styles.value}>{usSpend.join(', ')}</span>
            )
          ) : (
            <span className={styles.value}>-</span>
          ),
      });
    }

    // 4
    if (data.app_questionary.mmp) {
      let mmp = [];

      if (data.app_questionary.mmp.adjust) mmp.push('Adjust');
      if (data.app_questionary.mmp.appsflyer) mmp.push(`Appsflyer`);
      if (data.app_questionary.mmp.branch) mmp.push('Branch');
      if (data.app_questionary.mmp.dontKnow) {
        mmp.push('I don’t know');
      }

      if (data.app_questionary.mmp.firebase) mmp.push('Firebase');
      if (data.app_questionary.mmp.kochava) mmp.push('Kochava');
      if (data.app_questionary.mmp.other) mmp.push('Other');
      if (data.app_questionary.mmp.tenjin) mmp.push('Tenjin');

      rows.push({
        name: <span className={styles.label}>Your current MMP (tracking service):</span>,
        value:
          mmp.length > 0 ? (
            mmp[0] === 'I don’t know' ? (
              <span className={styles.value}>I don’t know</span>
            ) : (
              <span className={styles.value}>{mmp.join(', ')}</span>
            )
          ) : (
            <span className={styles.value}>-</span>
          ),
      });
    }

    // 5
    rows.push({
      name: <span className={styles.label}>Additional info about the project:</span>,
      value:
        data.app_questionary.additionalInfo.length > 0 ? (
          <span className={styles.value}>data.app_questionary.additionalInfo</span>
        ) : (
          <span className={styles.value}>-</span>
        ),
    });

    setDontKnow(dontCount);

    return rows;
  }

  function rejectedCompany() {
    companyData!.status = StatusEnumTitle.REJECTED;
  }

  useEffect(() => {
    updateCompany();
    // eslint-disable-next-line no-unused-expressions
    companyId && getPdsAccounts({ params: { company_id: companyId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  // get onboarding data
  function getOnboardingData(): any {
    const sca = window.ENV?.REACT_APP_SCA_URL || process.env.REACT_APP_SCA_URL;

    return baseRequest({
      path: `${sca}/v1/onboarding/${companyId}`,
      options: {
        method: 'GET',
      },
    });
  }

  useEffect(() => {
    getOnboardingData()
      .then((data: any) => setOnboardingData(mapOnboardingData(data)))
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (companyData) {
      setStatus(companyData?.status);
    }
  }, [companyData, setCompanyData]);

  if (!companyData) return null;

  return (
    <div className={styles.wrapper}>
      <PageHeader
        title={companyData.name as string}
        back
        onBack={() => history.goBack()}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // leftContent={<CompanyStatusLabel status={companyData.status} />}
        leftContent={<CompanyStatusLabel status={status} />}
        rightContent={<ActionButton name={companyData.name as string} updateCompany={updateCompany} />}
      />
      <Tabs
        items={tabsItems.map((tab: any) => {
          const label =
            tab.label === 'App&Game Info' && dontKnowCount > 0 ? (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                }}
              >
                <div>{tab.label}</div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '1px 0px 0px',
                    borderRadius: '100px',
                    background: '#EC3E72',
                    width: '15px',
                    height: '16px',
                    color: '#FFF',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '14px',
                  }}
                >
                  {dontKnowCount}
                </div>
              </div>
            ) : (
              <>{tab.label}</>
            );

          return { label, key: tab.key, children: tab.children };
        })}
        activeKey={slug}
        onChange={(key: string) => {
          setShowAgreementButton(false);

          if (key) {
            history.push(`${urls.getCompany()}/${key}/${companyId}`);

            return;
          }
          history.push(`${urls.getCompany()}/${companyId}`);
        }}
        tabBarExtraContent={{
          right: showAgreementButton ? (
            <AgreementButton
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              currentDocument={currentDocument}
              setCurrentDocument={setCurrentDocument}
              company_id={companyId}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          ) : null,
        }}
      />
    </div>
  );
};

export default View;
